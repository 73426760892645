import { createUploadLink } from "apollo-upload-client"
import { setContext } from "apollo-link-context"
import React from "react"
import { backendUrl } from "./utils/env"
import { MuiThemeProvider } from "@material-ui/core/styles"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import themes from "./utils/themes"
import lazyLoadComponent from "./utils/lazyLoadComponent"
import LanguageContextComponent from "./context/LanguageContextComponent"
import { ApolloProvider, ApolloClient, InMemoryCache, from } from "@apollo/client"
import { onError } from "@apollo/client/link/error"


import "rc-slider/assets/index.css"
import 'bootstrap/dist/css/bootstrap.css'
import "./App.css"
import SaveToastContextProvider from "./context/SaveToastContext"
import UserTokenProvider, { useUserToken } from "./context/UserTokenContext"

const CandidateRoutes = lazyLoadComponent(() =>
  import("./candidate/routes/CandidateRoutes")
)
const App = lazyLoadComponent(() => import("./App")) as any;

const AuthorizedApp = () => {
  const { token, setToken } = useUserToken();

  const uploadLink = createUploadLink({
    uri: backendUrl
  })

  const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ""
      }
    }
  })

  const errorLink = onError((error) => {
    const authErrors = (error.graphQLErrors || []).filter(err => err.message === 'Not authorized')
    if (authErrors.length > 0) {
      console.log('clearing token')
      setToken('');
    }
  });


  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([errorLink, authLink, uploadLink]),

  });

  return (
    <ApolloProvider client={client}>
      <Router>
        <MuiThemeProvider theme={themes.main}>
          <Switch>
            <Route path="/candidate" component={CandidateRoutes} />
            <Route>
              <LanguageContextComponent>
                <App />
              </LanguageContextComponent>
            </Route>
          </Switch>
        </MuiThemeProvider>
      </Router>
    </ApolloProvider>
  )
}

const BaseApp = () => {
  return (
    <SaveToastContextProvider>
      <UserTokenProvider>
        <AuthorizedApp />
      </UserTokenProvider>
    </SaveToastContextProvider>
  )
}

export default BaseApp;
