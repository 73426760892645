/* eslint-disable */
export default {
  back: 'Tillbaka',
  to: "till",
  info: 'Info',
  openCV: "CV",
  ok: "OK",
  loadingCV: "Laddar CV...",
  errorMessage: "Något gick fel",
  close: "Stäng",
  save: "Spara",
  saving: "Sparar...",
  saved: 'Sparade',
  share: "Dela",
  name: 'Namn',
  firstName: 'Förnamn',
  lastName: 'Efternamn',
  email: 'Email',
  availableNow: "Tillgänglig omgående",
  availableFrom: "Tillgänglig från ",
  highestEducation: "Högsta utbildningsnivå: ",
  wantsMarketSalary: "Gör anspråk på marknadsmässig lön",
  wantSalarySpace: "Löneanspråk: ",
  krMonth: " kr i månaden",
  noWantSalary: "Valde att inte ange löneanspråk",
  questionsAnswers: "Frågor och svar",
  voiceAnswer: "Röstsvar",
  videoAnswer: "Videosvar",
  noShowPrint: "Kan ej visas i utskrivet format",
  recommendedAnswer: "Rekommenderat svar",
  applicantLanguages: "Språkkunskaper",
  confirmation: "Bekräftelse",
  thisActionCannotBeUndone: "Denna åtgärd kan inte ångras.",

  beginner: "Nybörjare",
  average: "Medel",
  advanced: "Avancerad",
  nativeLanguage: "Modersmål",
  skills: "Kompetenser",
  systemAndIt: "System och IT",
  assessmentApplicant: "Din bedömning av kandidaten",
  notAvailable: "Ej tillgängligt i förhandsvisning",
  grade: "Betyg",
  notSpecified: "ej angett",
  comment: "Kommentar",
  mandatoryField: "Indikerar obligatoriskt fält",
  sendingAssessment: "Skickar bedömning...",
  assessmentSent: "Bedömning inskickad",
  sendAssessment: "Skicka bedömning",
  responsibleRecruiter: "Ansvarig rekryterare",
  cancel: "Avbryt",
  yes: "Ja",
  no: "Nej",
  checkAll: "Markera alla",
  password: "Lösenord",
  confirmPasswrod: "Bekräfta lösenord",
  pieceShortcut: 'st',
  details: 'Detaljer',

  noPresentation: "Inga kandidatpresentationer",
  noCandidateSearchFound: "Inga kandidater matchar din sökning",

  // Job Settings
  jobSettings: 'Jobbinställningar',
  abilityToMark: 'Möjlighet att betygsätta och kommentera',

  // RecruiterProfilePage
  areYouSureCancel: "Säker på att du vill avbryta?",
  changesWontSave: "Ändringar kommer ej sparas",

  // CandidatePresentationView
  invalidUrl: "Länken är ogiltig",
  fillInCode: "Fyll i accesskod för att visa kandidaten",
  invalidCode: "Ogiltig accesskod",
  inactiveCode: "Inactive accesskod",
  accessCode: "Accesskod",
  show: "Visa",
  accessThroughCode: "Tillgång via accesskod",
  accessThroughLogin: "Tillgång via inloggad användare",
  avaibility: 'Tillgänglighet',
  notice: 'Uppsägningstid',
  salary: 'Löneanspråk',
  price: 'Pris / per tim',
  aboutCandidate: 'Om kandidaten',

  // CandidatePresentationEdit
  showAvaibility: 'Visa tillgänglighet',
  showNotice: 'Visa uppsägningstid',
  showSalary: 'Visa löneanspråk',
  showPrice: 'Visa pris / per tim',
  useThisPresentation: "Använd denna presentation",
  areYouSureApplyPresentation: 'Är du säker på att du vill använda denna presentation?',
  presentationWillBeChanged: 'Presentationen kommer att ändras',
  previewOfDocument: 'Förhandsgranskning av dokument',
  download: 'Ladda ned',

  // CustomerAnswered
  thankAnswer: "Tack för ditt svar!",
  assessmentSentTo: "Din bedömning har skickats till ansvarig rekryterare.",

  // MatchDocumentForm
  matchDocument: "Kandidatfrågor",
  addPicture: "Lägg till profilbild ",
  remove: "Ta bort",
  addResumePdf: "Lägg till CV (pdf, doxc, png, jpeg)",
  immediately: "Omgående",
  afterDate: "Efter datum",
  selectDate: "Välj datum",
  selectEducation: "Välj utbildningsnivå",
  levelEducation: "Utbildningsnivå",
  educationLevel: [
    "Grundskola",
    "Gymnasium",
    "Högskola/universitet - ej examen",
    "Högskola/universitet - högskoleexamen",
    "Högskola/universitet - kandidatexamen",
    "Högskola/universitet - magister/master",
    "Högskola/universitet - doktorsexamen"
  ],
  other: "Annat",
  marketSalary: "Marknadsmässig",
  specify: "Ange",
  noSpecify: "Ange ej",
  salaryInSEK: "Månadslön i svenska kronor",
  SEK: "kr",
  applicantLanguagesSpace: "Språkkunskaper ",
  language: "Språk",
  education: "Utbildningar",
  experience: "Erfarenhet",
  documnet: 'Dokument/CV',
  offer: 'Offert/dok',
  chooseLanguage: "Välj språk",
  languageLevel: 'Nivå',
  chooseLevel: "Välj nivå",
  addLanguage: "Lägg till språk",
  addCompetence: "Lägg till kompetens",
  competenceQuestions: "Kompetensfrågor",
  pleaseGrade:
    "Var vänlig gradera din kompetens inom föjande områden genom att dra i skjutreglaget.",
  questionsWithMultiple: "Frågor med flera svarstyper",
  missingAnswers: "Det saknas svar på obligatoriska fält",
  addResume: "Lägg till CV",
  selectDateFrom: "Välj vilket datum du är tillgänglig från",
  chooseHighestEducation: "Välj din högsta utbildningsnivå",
  wantSalary: "Löneanspråk",
  languageMissing: "En språkkunskap saknar språk eller nivå",
  addLeastOne: "Lägg till minst en språkkunskap",
  competenceMissing: "Någon av kompetensfrågorna saknar svar",
  questionMultipleMissing: "Någon av frågorna med flera svarstyper saknar svar",
  documentReady: "Kandidatfrågorna är redo att skickas in",
  uploadingAnswers: "Laddar upp svar...",
  sendIn: "Skicka in svar",

  // CandidateFormAssignment
  of: "av",
  chooseWay: "Välj svarstyp",
  text: "Text",
  video: "Video",
  voice: "Röst",
  deleteAnswer: "Radera svar",
  textAnswerTitle: "Textsvar",
  writeAnswer: "Skriv ditt svar.",
  correctSpelling: "Se till att du stavar rätt.",
  answerYourself: "Skriv svaret själv.",
  videoAnswerTitle: "Videosvar",
  unlimitedAnswers: "Du får hur många försök du vill på dig att spela in.",
  recommendedLenghtVideo:
    "Rekommenderad längd på ett videosvar är 60 sekunder.",
  supportedFormatVideo: "Filformatet som stödjs är .mp4.",
  voiceAnswerTitle: "Röstsvar",
  recommendedLenghtVoice: "Rekommenderad längd på ett röstsvar är 60 sekunder.",
  supportedFormatVoice: "Filformaten som stödjs är .mp3 och .mp4.",
  sendQuestionsToCandidate: 'Skicka jobbets kandidatfrågor till kandidaten via Simplycruit?',

  // RecordNowButtonWithPreview
  chosenVideo: "Vald video",
  uploadFile: "Ladda upp fil",
  recordNow: "eller spela in nu",
  useCamera: "Använd webbkamera",
  largeVideoFile: "Videofilen är för stor",

  // RecordNowButton
  noSupport: "Inget webbläsarstöd",

  // RecordVoiceButtonWithPreview
  useMic: "Använd mikrofon",
  onIphone: "På iPhone?",

  // AlreadyAnswered
  goodJob: "Tack för ditt svar!",
  youAnswered: "Du svarade ",
  weWillGetBackToYou:
    "Vi återkommer till dig gällande nästa steg i rekryteringsprocessen.",
  youCanClose: "Du kan nu stänga denna sida.",

  // ProgressBarPage
  uploading: "Laddar upp...",
  noClose: "Stäng inte browserfönstret.",

  // Organization
  hereChangePassword: "Här kan du ändra ditt lösenord",
  newUser: "Ny Användare",

  // Candidate Bank
  job: "Jobb",
  confirm: "Bekräfta",
  addToJob: "Lägg till jobb",
  createNew: "Skapa ny",
  noCandidateChosen: 'Ingen kandidat vald',

  // Job Bank
  candidate: 'Kandidat',
  candidates: 'Kandidater',
  allCandidates: 'Alla kandidater',
  sharedCandidates: 'Delade kandidater',
  addCandidate: 'Lägg till kandidat',
  noPresentationChosen: 'Ingen kandidatpresentation vald',
  allCandidateText: 'Klicka på kandidatkortet för att redigera info som ska delas. Välj kandider att dela genom att markera kryssrutan.',
  imported: 'Importerad',
  shared: "Delad ",
  edited: "Redigerad",
  notEdited: "Ej redigerad",
  choosePresentation: "Välj presentation",
  candidateInJobsCount: "Denna kandidat finns i ${count} jobb",
  candidateMatchedOnEmail: "Kandidat är matchad via e-post adress",
  candidateHasNoEmail: 'Kandidaten har ingen e-post så ingen presentation kommer att hittas.',
  basePresentation: "Grundpresentation",
  deleteApplicantFromThisJob: "Vill du radera ${candidate} från detta jobb?",
  deleteApplicantFromBank: "Vill du ta bort denna kandidat?",
  changesPresentationWontSave: "Redigeringar som gjorts i presentationen för just detta jobb kommer ej att sparas",
  archive: 'Arkivera',
  archived: 'Arkiverad',
  activate: 'Aktivera',
  myPresentations: 'Mina kandidatpresentationer',
  myArchive: 'Mitt Arkiv',
  thisOperationCannotBeUndone: 'Denna åtgärd kan inte ångras.',
  claimPresentation: 'Ta över presentation',


  // New Job Form
  addCandidateQuestions: 'Lägg till kandidatfrågor till jobbet',
  recruiting: 'Rekrytering',
  staffing: 'Bemanning',
  interim: 'Interim',
  type: 'Typ',

  // Sharing
  link: 'Länk',
  copyLink: 'Kopiera länk',
  linkCopied: 'Länk kopierad',
  copyCode: 'Kopiera kod',
  codeCopied: 'Kod kopierad',
  nameOnLink: 'Namn på länk',
  kandidatesInLink: 'Kandidater i länk',
  views: 'Visningar',
  linkActiveTo: 'Länk aktiv till',
  activeTo: 'Aktiv till',
  active: 'Active',
  addCandidateToLink: 'Lägg till kandidat i länk',
  candiatesInThisLink: 'Kandidater i denna länk',
  preview: 'Förhandsgranskning',
  writeComment: 'Skriv kommentar',
  print: 'Skriv ut',
  addNote: 'Ange',
  notesDescription: 'Din bedömning av kandidaten: Gradera och/eller ange kommentar.',
  notesDescriptionHideMarks: 'Din bedömning av kandidaten: Ange kommentar.',
  optional: 'valfritt',
  notVisibleToCandidate: 'visas Ej för kandidat',
  deleteApplicantFromThisLink: "Vill du radera ${candidate} från denna länk?",
  markAndNotes: 'Betyg och kommentarer',
  chooseApplicant: 'Välj Kandidat',
  markForChoosen: 'Snittbetyg för valt kandidat',
  areYouSureDeleteNote: 'Är du säker på att du vill radera denna kommentar?',
  landingPage: 'Landingssida',

  // Attachments
  addDialogTitle: 'Lägg till dokument',
  uploadDialogTitle: 'Laddar upp dokument',
  attachmentsErrorMessage: 'Något gick fel, går inte att ladda upp just nu',
  uploadProgressMessage: 'Uppladdning pågår...',
  uploadSucessMessage: 'Uppladdning klar!',

  // Education / Experience
  addEducation: 'Lägg till utbildning',
  school: 'Skola',
  educationType: 'Examen/Typ',
  addExperience: 'Lägg till erfarenhet',
  company: 'Företag',
  title: 'Titel',
  year: 'År',
  yearFrom: 'Från',
  yearTo: 'Till',


  // EMAIL mailto - TODO only temporary, should be converted to template
  mailtoGroupLink:
    "Hej!\n\nI länken nedan finner du information om de kandidater som jag vill presentera.\nVi använder oss av ett digitalt verktyg när vi presenterar kandidater för att stärka vår kund-och kandidatupplevelse och säkra GDPR.\nDetta innebär att länken med informationen om kandidaten kommer att vara aktiv under en begränsad tid men går alltid att aktivera igen.\nTack för att du vill vara med och säkra våra processer – tillsammans skapar vi en hållbar rekrytering.\n\nKod för att komma åt kandidatfrågor:${accessToken}\n\nSe kandidatprofiler nedan:\n${link}",
  mailtoGroupLinkTokenIncluded:
    "Hej!\n\nI länken nedan finner du information om de kandidater som jag vill presentera.\nVi använder oss av ett digitalt verktyg när vi presenterar kandidater för att stärka vår kund-och kandidatupplevelse och säkra GDPR.\nDetta innebär att länken med informationen om kandidaten kommer att vara aktiv under en begränsad tid men går alltid att aktivera igen.\nTack för att du vill vara med och säkra våra processer – tillsammans skapar vi en hållbar rekrytering.\n\nSe kandidatprofiler nedan:\n${link}",
  mailtoCandidateQuestions:
    "Hej\n\nSom en del i rekryteringen av ${position} till ${company} vill vi att du besvarar frågorna i Kandidatprofil.\nKandidatfrågorna tillsammans med ditt CV stärker det underlag jag presenterar för vår kund.\nVi använder oss av ett digitalt verktyg när vi presenterar kandidater för att stärka vår kandidat-och kundupplevelse och säkra GDPR.\n\nTack för att du vill vara med och säkra våra processer – tillsammans skapar vi en hållbar rekrytering.\n\nSvara på frågor i länken:\n${link}",

  // Search
  typeMoreToSearch: 'Ange minst 3 bokstäver för att söka',
  recruiters: 'Rekryterare',
  jobs: 'Jobb',

  // Group
  viewCandidates: 'Visa kandidater',
  presentationOfCandidates: 'Presentation av kandidater',

  // Statistics
  statistics: 'Statistik',
  totalViews: 'Totala visningar',
  browser: 'Webbläsare',
  os: 'Operativsystem',
}
