// import "react-app-polyfill/ie11"
// import "react-app-polyfill/stable"
import * as React from "react"
import * as ReactDOM from "react-dom"
import { unregister } from "./registerServiceWorker"

import AuthorizedApp from "./AuthorizedApp"
import "react-datepicker/dist/react-datepicker.css"
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-quill/dist/quill.snow.css';

ReactDOM.render(<AuthorizedApp />, document.getElementById(
  "root"
) as HTMLElement)
unregister()
